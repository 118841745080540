<template>
    <div :class=" imgUrl ? 'sharePoster' : '' ">
        <div class="share-give-poster" ref="creatGivePoster">
            <img class="poster-img"  :src="shareImg" ref="shareImg"/>
            <div class="code">推荐码 1233211234567</div>
        </div>
        <van-loading v-if="!imgUrl" size="24px" vertical type="spinner" color="#1989fa" style="margin-top: 30px;">生成海报中</van-loading>
        <img v-else :src="imgUrl" alt="" style="width: 100%;height: 810px;" />
    </div>
</template>

<script>
import html2canvas from 'html2canvas';
export default {
    data(){
        return {
            shareImg: require('@/assets/activeImgs/shareImg.png'),
            imgUrl: ''
        }
    },
    mounted(){
        this.$nextTick(()=>{
            let imgURL = this.$refs.shareImg;
            let that = this;
            imgURL.onload = function() {
                that.getImg();
            }
        })
    },
    methods: {
        getImg(){
            let shareContent = this.$refs.creatGivePoster //需要截图的包裹的（原生的）DOM 对象
            let width = shareContent.offsetWidth //获取dom 宽度
            let height = shareContent.offsetHeight //获取dom 高度
            let canvas = document.createElement('canvas') //创建一个canvas节点
            let scale = 2 //定义任意放大倍数 支持小数
            canvas.width = width * 2; //定义canvas 宽度 * 缩放
            canvas.height = height * 2; //定义canvas高度 *缩放
            canvas.fillStyle = '#fff';
             let opts = {
                scale: scale, // 添加的scale 参数
                canvas: canvas, //自定义 canvas
                // logging: true, //日志开关，便于查看html2canvas的内部执行流程
                width: width, //dom 原始宽度
                height: height,
                useCORS: true, // 【重要】开启跨域配置
                backgroundColor: null,
             }
             html2canvas(shareContent, opts).then(canvas => {
                var context = canvas.getContext('2d')
                // 【重要】关闭抗锯齿
                context.mozImageSmoothingEnabled = false
                context.webkitImageSmoothingEnabled = false
                context.msImageSmoothingEnabled = false
                context.imageSmoothingEnabled = false
                // 【重要】默认转化的格式为png,也可设置为其他格式
                let imgUrl = canvas.toDataURL('image/png')
                this.imgUrl = imgUrl;
                console.log('生成海报')
             })
        }
    }
}
</script>

<style lang='scss' scoped>
    .sharePoster{
         height: 810px;
         overflow:hidden;
    }
    .share-give-poster{
        position: fixed;
        top: -5000px;
        width: 100%;
        height: 810px;
        .poster-img{
            width: 100%;
            height: 100%;
        }
        .code{
            position: absolute;
            top: 461px;
            left: 113px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: bold;
            line-height: 20px;
            color: #357EFE;
            letter-spacing: 0.5px;
        }
    }
</style>